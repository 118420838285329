// import { importValidationSchemas, revenueSchema } from "../common/inputValidation.constants";
import * as yup from "yup";
import {
  taxSchemeList, 
  adjustPerYearList,   
} from "../common/inputGridColumns.constants";

export const repeatPeriodList = ["monthly", "yearly", "oneTime"];

// --------------------------------------------------
function runValidation(inputArray, validateSchema) {
  let outputArray = [];
  console.log(" inputArray:", inputArray);
  console.log(" validateSchema:", validateSchema);

  // Map the input array to an array of promises
  inputArray.forEach((row, index) => {
    console.log(" row:", row);
    try {
      validateSchema.validateSync(row, { abortEarly: false });
      // revenueSchema.validateSync(row, { abortEarly: false });
      // console.log("validData (", index, ") ", row);
      outputArray.push({ ...row, _valid: true, _index: index });
    } catch (err) {
      // If invalid, return the invalid data and the errors
      console.log(typeof err);
      console.log("Error name:", err.name); // Outputs the type of error
      console.log("--------------------");
      console.log(err)
      console.log("--------------------");

      if (err instanceof yup.ValidationError) { 
        console.log("Validation Errors:", err.errors); 
        console.log("Validation Path:", err.path); 
        console.log("Validation Value:", err.value); 
      } else { 
        console.error("An unexpected error occurred:", err); 
      }
      const obj = {
        _valid: false,
        _index: index,
        ...err.value,
        _numErrors: err.errors.length,
        _errFields:  err.inner.map((detail) => detail.path).join(","),
        _errMessages: err.inner.map((detail) => detail.errors).join(","),
      };
      outputArray.push(obj);
    }
  });

  console.log("outputArray:", outputArray);
  return outputArray;
}

// --------------------------------------------------
function dateValidation(dateList) {
  const debug = false;
  debug && console.log("dateValidation - dateList:", dateList);

  return (value, context ) => {
      const thisField = context.path;
      debug && console.log("dateValidation - value(", value, ") thisField(", thisField, ") type(", typeof value,")");
  
      if ( thisField === "earliestWithdrawalDate" && value === "" ) {
        debug && console.log("dateValidation - earliestWithdrawalDate, and empty => OK");
        return true; 
      }

      if (typeof value === 'string' && dateList.includes(value)) {        
        debug && console.log("dateValidation - value(", value, ") is prefined - OK");
        return true; // text is a valid predefined date
      }

      // if endDate, and empty and frequency = "oneTime" => OK
      if ( thisField === "endDate" && context.parent.repeatPeriod === "oneTime" && value === "" ) {
        debug && console.log("dateValidation - endDate, and empty and frequency = 'oneTime' => OK");
        return true; // This field is 'endDate' and a valid date but there is no 'startDate' to compare against
      }

      // try to convert to a date
      debug && console.log("dateValidation - trying to convert(", value, ") to a date field");
      const dateValue = new Date(value);
      debug && console.log("dateValidation - result(", dateValue, ") type(", typeof dateValue,") isNaN(dateValue):( ", isNaN(dateValue), ")");
      if (typeof dateValue !== 'object' || isNaN(dateValue) ) {
        debug && console.log("dateValidation - value(", value, ") is not a date - INVALID");
        return false; // Invalid value
      }
      debug && console.log("dateValidation - dateValue(", dateValue, ")");
      // this field is a Date
      if ( thisField !== "endDate" ) {
        debug && console.log("dateValidation - (", value, ") - Date (not endDate) OK");
          return true; // It's a not 'endDate' and its a valid date
      }

      // this is endDate

      // this field is 'endDate' and a valid date
      debug && console.log("dateValidation - (", dateValue, ") - Field is endDate");
      // check it is greater than 'startDate'
      debug && console.log("dateValidation - context(", context, ")");
      debug && console.log("dateValidation - startDate(", context.parent.startDate, ")");
      if ( ! context.parent.startDate ) {
        debug && console.log("dateValidation - no startDate");
          return true; // This field is 'endDate' and a valid date but there is no 'startDate' to compare against
      }

      const startDate = new Date(context.parent.startDate);
      debug && console.log("dateValidation - startDate(", context.parent.startDate, ") converted (", startDate,") type(", typeof startDate,")");
      if ( ! isNaN(startDate)) {
          // startDate is a valid date
          if ( dateValue >= startDate ) {
            debug && console.log("dateValidation - endDate(", dateValue, ") - after startDate(", startDate,") OK");
              return true; // This field is 'endDate', a valid date, and it's after startDate
          }
      } else {
          // startDate is not a valid date. We assume the validation of 'startDate' will catch this
          // This field is 'endDate', a valid date but we can't compare it against startDate
          debug && console.log("dateValidation - endDate(", dateValue, ") - but startDate(", startDate,") not valid");
          return true; 
      }
      debug && console.log("dateValidation - value(", value, ") fieldName(", thisField, ") type(", typeof value,") - INVALID");
      return false; // Invalid value

  }
}


// --------------------------------------------------
function personValidation(personList) {
  const debug = false;
  debug && console.log("personValidation - personList:", personList);

  return (inputPeopleString ) => {
  // split inputPeopleString by ";" character and check that each part is in importedPeopleList
  const inputPeopleArray = inputPeopleString.split(/[;:,]/);
  debug && console.log("personValidation - inputPeopleString", inputPeopleString, "valid people:", personList );
  for (let i = 0; i < inputPeopleArray.length; i++) {
    const inputPerson = inputPeopleArray[i].trim();
    debug && console.log("personValidation - inputPerson:", inputPerson);
    if ( ! personList.includes(inputPerson)) {
      debug && console.log("personValidation - inputPerson(", inputPerson,") not in personList(", personList,")");
      return false;
    }
  }
  return true;
  }
}
// --------------------------------------------------

function frequencyValidation() {
  const debug = true;
  if (debug) console.log("frequencyValidation");

  return true;
/*
  return (value, context) => {
    if (debug) console.log("context (", context, ")");
    const repeatPeriod = context.parent.repeatPeriod;
    if (debug) console.log("frequencyValidation - value(", value, ") repeatPeriod(", repeatPeriod, ")");
    
    if (repeatPeriod === 'oneTime') {
      if (debug) console.log("frequencyValidation oneTime");
      return value === 0 || value === 1 || value === undefined || value === null;
    } else {
      return yup.number().positive().isValidSync(value);
    }
  };
  */
}

// --------------------------------------------------
export function validatePeople(peopleData) {
  const peopleSchema = yup.object().shape({
    name: yup.string().required(),
    birthDate: yup.date().required(),
    lifespanAge: yup.number().positive().required(),
//    retireDate: yup.date().optional(),
  });
  return runValidation(peopleData, peopleSchema);
}

export function validateDates(datesData) {
  const dateSchema = yup.object().shape({
    name: yup.string().required(),
    date: yup.date().required(),
    comment: yup.string().optional(),
  });
  return runValidation(datesData, dateSchema);
}

export function alwaysTrueTest() {
  console.log("alwaysTrueTest");
  return true;
}

export function validateRevenues(revenuesData, importedDateList, importedPeopleList) {
  const debug = true;
  debug && console.log("validateRevenues - revenuesData 44:", revenuesData, "importedDateList:", importedDateList, "importedPeopleList:", importedPeopleList);
  const revenueSchema = yup.object().shape({
    name: yup.string().required(),
    amount: yup.number().positive().required(),
    repeatPeriod: yup
      .string()
      .oneOf(['oneTime', 'monthly', 'yearly'])
      .required('Period is required'),

      // OK number().positive().integer().required('Frequency is required'),
      // OK repeatFreq: yup.mixed().test(alwaysTrueTest),
      // OK repeatFreq: yup.mixed().notRequired(),

      repeatFreq: yup 
      .mixed() 
      .when('repeatPeriod', (repeatPeriod, schema) => { 
        if (repeatPeriod === 'oneTime') { 
          return yup.mixed().nullable();            
        } else { 
          return yup
              .number() 
              .positive() 
              .integer() 
              .required('Frequency is required'); 
        } 
      }),

      /*
        repeatFreq: yup
        .mixed()
        .when('repeatPeriod', {
          is: (value) => value === 'monthly',
          then: yup.mixed().notRequired(),
          otherwise: yup.mixed().notRequired()
        }),

          /* TEST ALWAYS PASSES
          .test( 'is-valid',
              'Must be 0, null, NaN, or an empty string', 
              // (value) => value === 0 || value === null || Number.isNaN(value) || value === '' || value === 'NaN' 
              (value) => value === 0 || value === null || value === '' || value === 'NaN'
            );
      */


      // then: yup.mixed().nullable(), 
      // then: yup.number().nullable().transform((_, originalValue) => originalValue === '' ? null : originalValue).nullable(),
      // then: yup.mixed().test(alwaysTrueTest)
      // otherwise: yup.mixed().notRequired()

    /*
      then: yup
        .mixed()
        .test(
          'always-true-test',
          'Validation error message',
          (value, context) => { 
            console.log('repeatPeriod:', context.parent.repeatPeriod);
            console.log('repeatFreq:', value); 
            // Replace this with your actual validation logic 
            return true; 
          }
        ),

*/


    /*
      repeatFreq: yup
    .mixed()
    .when('repeatPeriod', {
      is: 'monthly',
      // then: yup.mixed().nullable(), 
      // then: yup.number().nullable().transform((_, originalValue) => originalValue === '' ? null : originalValue).nullable(),
      then: yup.mixed().test(alwaysTrueTest)
    }),
*/
    startDate: yup
      .mixed()
      .test(
        "dateOrPredefined",
        "Invalid start date",
        dateValidation(importedDateList)
      ),
    endDate: yup
      .mixed()
      .test(
        "dateOrPredefined",
        "Invalid end date",
        dateValidation(importedDateList)
      ),
      person: yup.string().test('person', 'Invalid person', personValidation(importedPeopleList)).required(),
    adjustPerYear: yup.string().oneOf(adjustPerYearList, 'Invalid yearly adjustment').optional(),
    taxScheme: yup.string().oneOf(taxSchemeList, 'Régime fiscal invalid').optional(),
  });
  return runValidation(revenuesData, revenueSchema);
}


export function validateExpenses(expensesData, importedDateList, importedPeopleList) {
  const debug = false;
  debug && console.log("validateExpenses - expensesData:", expensesData, "importedDateList:", importedDateList, "importedPeopleList:", importedPeopleList);
  const expenseSchema = yup.object().shape({
    name: yup.string().required(),
    amount: yup.number().positive().required(),

    /*
    repeatPeriod: yup.string().oneOf(repeatPeriodList).required(),
    repeatFreq: yup.mixed().test('repeatFreq', 'Invalid repeat frequency', frequencyValidation()).optional(),
    */
    /*
    repeatFreq: yup.mixed().when('repeatPeriod', {
      is: 'oneTime',
      then: yup.mixed().nullable(),
      otherwise: yup.mixed().test('repeatFreq', 'Invalid repeat frequency', frequencyValidation())
    }),
    */

    luxury: yup.number().oneOf([0, 1]).required(),
    person: yup.string().test('person', 'Invalid person', personValidation(importedPeopleList)).required(),
    adjustPerYear: yup.string().oneOf(adjustPerYearList, 'Invalid yearly adjustment').optional(),
    startDate: yup
      .mixed()
      .test(
        "dateOrPredefined",
        "Invalid start date",
        dateValidation(importedDateList)
      ),
    endDate: yup
      .mixed()
      .test(
        "dateOrPredefined",
        "Invalid end date",
        dateValidation(importedDateList)
      ),
    });
  return runValidation(expensesData, expenseSchema);
}

export function validateParameters(parametersData) {
  const parameterSchema = yup.object().shape({
    name: yup.string().required(),
    value: yup.string().required(),
  });
  return runValidation(parametersData, parameterSchema);
}


export function validateAccounts(accountData, importedDateList, importedPeopleList) {
  const debug = false;
  debug && console.log("validateAccounts - accountData:", accountData, "importedDateList:", importedDateList, "importedPeopleList:", importedPeopleList);
  const accountSchema = yup.object().shape({
    name: yup.string().required(),
    person: yup.string().test('person', 'Invalid person', personValidation(importedPeopleList)).required(),
    initialCapital: yup.number().min(0).optional(),
    taxScheme: yup.string().oneOf(taxSchemeList, 'Régime fiscal invalid').optional(),
    earliestWithdrawalDate: yup
      .mixed()
      .test(
        "dateOrPredefined",
        "Invalid earliest withdrawal date",
        dateValidation(importedDateList)
      ),
  })
  return runValidation(accountData, accountSchema);
}


export function validateHoldings(holdingData, supportList) {
  const debug = false;
  debug && console.log("validateHoldings - holdingData:", holdingData, "supportList:", supportList);
  const holdingSchema = yup.object().shape({
    support: yup.string().oneOf(supportList).required(),
    currentValue: yup.number().min(0).required(),
  })
  return runValidation(holdingData, holdingSchema);
}

