

function maxId(list) {
  return list.length === 0 ? 0 : Math.max(...list.map((list) => list.id));
}

// importSimpleData
//    import data from validImportData into list
//       validImportData:     object containing valid data to import
//       setValidImportData:  function to set validImportData
//       type:                type of data to import
//       list:                list of data to import into
//       setList:             function to set list

const integerFieldNames = ["amount", "lifespanAge", "repeatFreq" ];

export function importSimpleData( validImportData, setValidImportData, type, list, setList) {
  console.log("importSimpleData - start (", type, ") : validImportData[type]:", validImportData[type]);
  console.log("importSimpleData - start (", type, ") : list:", list);

  // load valid data 
  if (validImportData && validImportData[type]) {
    const validData = validImportData[type];
    const newList = [...list];
    console.log("importSimpleData (", type, ") existing:", newList, " to import:", validData);
    // if validData contains "maintenant" or "jamais", remove them from list
    // check if validData contains "maintenant" or "jamais"
    const nowIndex = validData.findIndex((row) => row.name.toLowerCase() === "maintenant" || row.name.toLowerCase() === "now");
    if (nowIndex >= 0) {
      // remove "maintenant" or "now" from list
      // find index of "maintenant" or "now" in list
      const nowListIndex = newList.findIndex((row) => row.name.toLowerCase() === "maintenant" || row.name.toLowerCase() === "now");
      if (nowListIndex >= 0) {
        // remove "maintenant" or "now" from list
        // list.splice(nowListIndex, 1);
        newList.splice(nowListIndex, 1);
        console.log("importSimpleData - removing default entry 'maintenant' or 'now' from list so it can be overwritten by import");
      }    

    }
    const neverIndex = validData.findIndex((row) => row.name.toLowerCase() === "jamais" || row.name.toLowerCase() === "never");
    if (neverIndex >= 0) {
      // remove "jamais" or "never" from list
      // find index of "jamais" or "never" in list
      const neverListIndex = newList.findIndex((row) => row.name.toLowerCase() === "jamais" || row.name.toLowerCase() === "never");
      if (neverListIndex >= 0) {
        // remove "jamais" or "never" from list
        // list.splice(neverListIndex, 1);
        newList.splice(neverListIndex, 1);
        console.log("importSimpleData - removing default entry 'jamais' or 'never' from list so it can be overwritten by import");
      }
    }

    var investAccount = "";
    var investPerson = "";
    var parameterAccountIdx = -1;
    var parameterPersonIdx = -1;
    validData.forEach((row, index) => {
      var addItem = true;
      // only insert new entry if person is valid, not already imported and does not already exist
      if (row._valid && !row._imported) {
        /* The following code rejects a revenue called "Salaire" for different people
        if ( newList.some((p) => p.name.toLowerCase() === row.name.toLowerCase()) ) {
          validData[index]._errMessages = type + " '" + row.name + "' already exists";
          addItem = false;
        } 
        else 
        */
        if ( type === "parameters" ) {
          console.log("importSimpleData (parameter) row:", row);
          // remove trailing spaces from name
          row.name = row.name.trim();
          console.log("importSimpleData (parameter) row (trimmed):", row);
          if ( row.name.toLowerCase() === "invest.account" ) {
            console.log("importSimpleData (parameter) row (invest.account) - type:", typeof row.value);
            addItem = false;
            if (typeof row.value === 'string') {
              investAccount = row.value.trim()
              parameterAccountIdx = index;
              console.log("importSimpleData (parameter) - investAccount:", investAccount, " investPerson idx:", parameterAccountIdx);
            }
          } 
          else if ( row.name.toLowerCase() === "invest.person" ) {
            console.log("importSimpleData (parameter) row (invest.person) - type:", typeof row.value);
            addItem = false;
            if (typeof row.value === 'string') {
              investPerson = row.value.trim()
              parameterPersonIdx = index;
              console.log("importSimpleData (parameter) - investPerson:", investPerson, " investPerson idx:", parameterPersonIdx);
            }
          } 
        }

        if (addItem) {
          // remove fields from row that should not be added to list
          const { _valid, _imported, _errMessages, _index, ...rest } = row;
          
          // "rest is a dict. If any field name in "rest" is in integerFieldNames, convert value to integer
          for (const [key, value] of Object.entries(rest)) {
            if (integerFieldNames.includes(key)) {
              const parsed =  parseInt(value);
              if ( parsed !== rest[key] ) {
                validData[index]._errMessages = 'convert (' + rest[key] + ') to (' + parsed + ')';
                console.log("importSimpleData (", type,") converting", key, "value(", value, ") to integer");
                rest[key] = parsed;
                console.log("importSimpleData (", type,") converting valid data", key, "value(", value, ") to integer");
                // convert corresponding original data in validData to integer
                validData[index][key] = parseInt(value);
              }
            }
          }
          newList.push({
            // add all fields from row except _valid & _imported
            ...rest,
            id: maxId(newList) + 1,
            // retireDate: person.retireDate
          });
          validData[index]._imported = true;
          // append "IMPORTÉ" to current message in  _errMessages
          validData[index]._errMessages = (validData[index]._errMessages ? validData[index]._errMessages + " / " : "") + "IMPORTÉ";
        }
      }
    });

    console.log("importSimpleData (", type,") setting invest.account to:", investPerson + " / " + investAccount);
    // if type = parmeter and both "invest.person" and invest.account & invest.person are set, add them to list
    if (type === "parameters" && investAccount !== "" && investPerson !== "") {
        console.log("importSimpleData (parameter) setting invest.account to:", investPerson + " / " + investAccount);
        newList.push({
          // add all fields from row except _valid & _imported
          name: "invest.account",
          value: investAccount,
          id: maxId(newList) + 1,
        });
        newList.push({
          // add all fields from row except _valid & _imported
          name: "invest.person",
          value: investPerson,
          id: maxId(newList) + 1,
        });
        validData[parameterAccountIdx]._imported = true;
        validData[parameterAccountIdx]._errMessages = "IMPORTÉ";
        validData[parameterPersonIdx]._imported = true;
        validData[parameterPersonIdx]._errMessages = "IMPORTÉ";        
      }

      



    setValidImportData(validImportData, { [type] : validData });
    console.log("importSimpleData (", type, ") adding:", newList);
    console.log("importSimpleData (", type, ") validData:", validData);
    // setList(newList);
    try {
      setList(newList);
      console.log("Line 125 (", type, ") adding:", newList, " success");
  } catch (error) {
      console.error("Line 125 (", type, ") adding:", newList, " error :", error);
  }
  }
}

export function importAccountData( validImportData, setValidImportData, accounts, setAccounts, holdings, setHoldings) {
  // load valid people
  if (validImportData && validImportData["accounts"]) {
    const validAccounts = validImportData["accounts"];
    const newAccounts = (accounts && accounts.length > 0) ? [...accounts] : [];
    console.log("importAccountData (accounts) existing:", accounts, " to import:", validAccounts);
    validAccounts
      .sort((a, b) => {
        return a.name.localeCompare(b.name) || a.person.localeCompare(b.person);
      })
      .forEach((account, index) => {
      // only insert new entry if person is valid, not already imported and does not already exist
      if (account._valid && !account._imported) {
        if (  accounts &&
              accounts.length > 0 &&
              accounts.some((p) => p.name.toLowerCase() === account.name.toLowerCase()) ) {
          validAccounts[index]._errMessages = "account '" + account.name + "' already exists";
        } else {
          // remove fields from account that should not be added to accounts
          const { _valid, _imported, _errMessages, _index, ...rest } = account;
          newAccounts.push({
            // add all fields from row except _valid & _imported
            ...rest,
//            id: maxId(newAccounts) + 1,
            // retireDate: person.retireDate
          });
          validAccounts[index]._imported = true;
          validAccounts[index]._errMessages = "IMPORTÉ";

        }
      }
    });
    setValidImportData(validImportData, { accounts: validAccounts });
    console.log("importAccountData (accounts) adding:", newAccounts);
    setAccounts(newAccounts);

    // load holdings for the accounts that were imported

    // get ids of accounts that were imported
    const importedAccountIds = validAccounts.filter((account) => account._imported).map((account) => account.id);
    console.log("importAccountData (holdings) importedAccountIds:", importedAccountIds);

    // get holdings for the imported accounts
    const validHoldings = validImportData["holdings"].filter((holding) => importedAccountIds.includes(holding.accountId));
    
    const newHoldings = [...holdings];
    console.log("importAccountData (holdings) existing:", holdings, " to import:", validHoldings);
    validHoldings.forEach((holding, index) => {
      // only insert new entry if person is valid, not already imported and does not already exist
      if (holding._valid && !holding._imported) {
        // remove fields from holding that should not be added to holdings
        const { _valid, _imported, _errMessages, _index, ...rest } = holding;
        newHoldings.push({
          // add all fields from row except _valid & _imported
          ...rest,
//          id: maxId(newHoldings) + 1,
          // retireDate: person.retireDate
        });
        validHoldings[index]._imported = true;
        validHoldings[index]._errMessages = "IMPORTÉ";

      }
    });
    setValidImportData(validImportData, { holdings : validHoldings });
    console.log("importAccountData (holdings) adding:", newHoldings);
    setHoldings(newHoldings);

  }
}
